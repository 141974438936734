import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "orderChartWrapper",
  style: {"height":"100%","width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createBlock(_component_el_skeleton, {
    loading: _ctx.loading,
    animated: ""
  }, {
    template: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_skeleton_item, { variant: "text" }),
        _createVNode(_component_el_skeleton_item, {
          variant: "text",
          class: "margin-top"
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, null, 512 /* NEED_PATCH */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading"]))
}